<template>
  <div class="content">
    <div class="content__title content__title--party content__title--start">
      <router-link
        :to="{ name: 'clients-list' }"
        class="button button--light button--no-indent button--small"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Добавление клиента</h1>
    </div>
    <form class="form" @submit.prevent="onCheckForm">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label for="name">Название<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.name.$error }">
              <input
                id="name"
                type="text"
                class="form-control__input"
                name="name"
                placeholder="Введите название"
                v-model.trim="form.name"
                @change="$v.form.name.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label for="instagram_url">Instagram</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.instagram_url.$error }">
              <input
                id="instagram_url"
                type="url"
                class="form-control__input"
                name="instagram_url"
                placeholder="Введите instagram"
                v-model.trim="form.instagram_url"
                @change="$v.form.instagram_url.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.instagram_url.$dirty && !$v.form.instagram_url.url">Поле должно быть ссылкой</div>
          </div>
          <div class="form-group">
            <label for="instagram_url">Комментарий</label>
            <div class="form-control">
              <textarea
                id="comment"
                class="form-control__textarea"
                name="comment"
                placeholder="Добавьте комментарий"
                v-model.trim="form.comment"
              />
            </div>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label>Основной контрагент 1С<sup>*</sup></label>
            <v-select
              label="name"
              v-model="form.main_counterparty_code"
              :filterable="false"
              :clearable="false"
              :options="main_codes"
              @search="onCodesSearch"
              placeholder="Выберите основного контрагента 1C"
              class="select"
              :class="{ 'select--error': $v.form.main_counterparty_code.$error }"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите свой запрос для поиска</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">
                  {{ option.name }} (Code: {{ option.code_1c }})
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.name }} (Code: {{ option.code_1c }})
                </div>
              </template>
            </v-select>
            <div class="form-group__error" v-if="$v.form.main_counterparty_code.$dirty && !$v.form.main_counterparty_code.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label>Дополнительные контрагенты 1С</label>
            <v-select
              label="name"
              v-model="form.counterparty_codes"
              :filterable="false"
              :clearable="false"
              :options="dop_codes"
              @search="onDopCodesSearch"
              placeholder="Выберите дополнительных контрагентов 1C"
              class="select select--multiple"
              multiple
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите свой запрос для поиска</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">
                  {{ option.name }} (Code: {{ option.code_1c }})
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.name }}
                </div>
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button class="button" type="submit" :class="{ 'preload': loading }" :disabled="$v.form.$error">
          <span>Добавить клиента</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, url } from 'vuelidate/lib/validators'
import { debounce } from 'lodash'

export default {
  name: 'ClientsCreate',
  mixins: [ validationMixin ],
  validations: {
    form: {
      name: { required },
      instagram_url: { url },
      main_counterparty_code: { required }
    },
  },
  data() {
    return {
      form: {},
      main_codes: [],
      dop_codes: [],
      loading: false,
    }
  },
  methods: {
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.loading = true
      this.form.main_counterparty_code = this.form.main_counterparty_code.code_1c
      if (this.form.counterparty_codes) {
        this.form.counterparty_codes = this.form.counterparty_codes.map(item => item.code_1c).filter(item => item !== this.form.main_counterparty_code)
      } else {
        this.form.counterparty_codes = []
      }
      this.$store.dispatch('clients/POST_DATA', this.form)
        .then(() => {
          this.loading = false
          this.$router.push({ name: 'clients-list' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Клиент добавлен.'
          })
        })
        .catch(() => {
          this.loading = false
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка добавления клиента.'
          })
        })
    },
    onCodesSearch (search, loading) {
      loading(true)
      this.codesOrg(loading, search, this)
    },
    codesOrg: debounce((loading, search, vm) => {
      vm.$store.dispatch('dict/GET_COUNTERPARTIES', search).then(response => {
        vm.main_codes = response.data.results
        loading(false)
      })
    }, 350),
    onDopCodesSearch (search, loading) {
      loading(true)
      this.dopCodesOrg(loading, search, this)
    },
    dopCodesOrg: debounce((loading, search, vm) => {
      vm.$store.dispatch('dict/GET_COUNTERPARTIES', search).then(response => {
        if (vm.form.main_counterparty_code) {
          vm.dop_codes = response.data.results.filter(item => item.code_1c !== vm.form.main_counterparty_code.code_1c)
        } else {
          vm.dop_codes = response.data.results
        }
        loading(false)
      })
    }, 350),
  }
}
</script>
